import React from "react";
import PropTypes from "prop-types";
import maillogo from "../images/footer-email-logo.svg";
import avantcyLogoDark from "../images/avantcy-marketing-logo-dark.svg";
import phoneLogo from "../images/nav-phone-logo.svg";

const Footer = () => (
  <footer>
    <div className={"desktop-footer m-hide"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-between"}>
          <div>
            <h3>What We Offer</h3>
            <ul>
              <li><a href={"/marketing-services/seo"}>Search Engine Optimization</a></li>
              <li><a href={"/marketing-services-web-design"}>Website Design and Development</a></li>
              <li><a href={"/marketing-services/social-media-marketing"}>Social Media Marketing</a></li>
              <li><a href={"/marketing-services/pay-per-click"}>Pay Per Click</a></li>
              <li><a href={"/marketing-services/content-marketing"}>Content Marketing</a></li>
            </ul>
          </div>
          <div>
            <h3>Quick Links</h3>
            <ul>
              <li><a href={"/"}>Home</a></li>
              <li><a href={"/about"}>About us</a></li>
              <li><a href={"/contact"}>Contact</a></li>
              {/*<li><a href={"#"}>FAQs</a></li>
            <li><a href={"#"}>Case Studies</a></li>
            <li><a href={"#"}>Terms of Use</a></li>
            <li><a href={"#"}>Privacy Policy</a></li>*/}
            </ul>
          </div>
          <div>
            <h3>Contact Information</h3>
            <ul>
              <li><a href={"#"}><img className={"icon"} alt={"Email logo"} src={maillogo}/>contact@avantcymarketing.com</a>
              </li>
              <li><img className={"icon"} alt={"Telephone logo"} src={phoneLogo}/><a
                href={"tel:1-617-682-5663"}>617-682-5663</a></li>
            </ul>
          </div>
        </div>
        <hr/>
        <div className={"d-flex"}>
          <a href={"/"}>
            <img alt={"Avantcy Marketing Logo"} src={avantcyLogoDark}/>
          </a>
          <span>© {new Date().getFullYear()} Avantcy Marketing LLC. All Rights Reserved</span>
        </div>
      </div>
    </div>
    <div className={"mobile-footer m-show d-none"}>
      <div className={"container section-padding-wrapper text-align-center"}>
        <h3>What We Offer</h3>
        <ul>
          <li><a href={"/marketing-services/seo"}>Search Engine Optimization</a></li>
          <li><a href={"/marketing-services-web-design"}>Website Design and Development</a></li>
          <li><a href={"/marketing-services/social-media-marketing"}>Social Media Marketing</a></li>
          <li><a href={"/marketing-services/pay-per-click"}>Pay Per Click</a></li>
          <li><a href={"/marketing-services/content-marketing"}>Content Marketing</a></li>
        </ul>
        <h3>Quick Links</h3>
        <ul>
          <li><a href={"/"}>Home</a></li>
          <li><a href={"/about"}>About us</a></li>
          <li><a href={"/contact"}>Contact</a></li>
          {/*<li><a href={"#"}>FAQs</a></li>
            <li><a href={"#"}>Case Studies</a></li>
            <li><a href={"#"}>Terms of Use</a></li>
            <li><a href={"#"}>Privacy Policy</a></li>*/}
        </ul>
        <h3>Contact Information</h3>
        <ul>
          <li><a href={"#"}>contact@avantcymarketing.com</a>
          </li>
          <li><a href={"tel:1-617-682-5663"}>+1-617-682-5663</a></li>
        </ul>
        <hr/>
        <span>© {new Date().getFullYear()} Avantcy Marketing LLC. All Rights Reserved</span>
        <a href={"/"}>
          <img alt={"Avantcy Marketing Logo"} src={avantcyLogoDark}/>
        </a>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
