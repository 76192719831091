/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import {useStaticQuery, graphql} from "gatsby";

import Header from "./header"
import "../styles/bootstrap-grid.min.css"
import "../styles/main.css"
import "normalize.css";
import Footer from "./footer";

const Layout = ({css, children}) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);

  return (
    <>
      <Header/>
        <main id={css}>{children}</main>
      <Footer/>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
