import PropTypes from "prop-types";
import React from "react";
import facebookLogo from "../images/nav-facebook-logo.svg";
import instagramLogo from "../images/nav-instagram-logo.svg";
import linkedInLogo from "../images/nav-linkedin-logo.svg";
import phoneLogo from "../images/nav-phone-logo.svg";
import avantcyLogo from "../images/avantcy-marketing-logo.svg";
import menuButton from "../images/menu_mobile.svg";

class Header extends React.Component {
  propTypes = {
    siteTitle: PropTypes.string,
  };
  defaultProps = {
    siteTitle: ``,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.toggleMenu = this.toggleMenu.bind(this)
  };

  toggleMenu() {
    this.setState({isMenuOpen: !this.state.isMenuOpen})
  };

  render() {
    return (
      <>
        <HeaderOnly
          onMenuToggle={this.toggleMenu}
        />
          <Menu
            isMenuOpen={this.state.isMenuOpen}
            onMenuToggle={this.toggleMenu}
          />
      </>
    );
  };
}

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {/* Awesome State Not Yet Used */}
  }

  render() {
    return (
      <div className={`sidebar-menu${this.props.isMenuOpen === true ? ' open' : ''}`}>
        <ul className="vertical menu">
          <li><a href="/">HOME</a></li>
          <li><a href="/about">ABOUT US</a></li>
          <li><a href="/marketing-services">ALL SERVICES</a></li>
          <li><a href="/marketing-services/social-media-marketing">SOCIAL MEDIA MARKETING</a></li>
          <li><a href="/marketing-services/seo">SEARCH ENGINE OPTIMIZATION</a></li>
          <li><a href="/marketing-services/pay-per-click">PAY PER CLICK</a></li>
          <li><a href="/marketing-services/web-design">WEBSITE DESIGN AND DEVELOPMENT</a></li>
          <li><a href="/marketing-services/content-marketing">CONTENT MARKETING</a></li>
          <li><a href="/contact">CONTACT US</a></li>
        </ul>
      </div>
    )
  }
}

class HeaderOnly extends React.Component {
  propTypes = {
    siteTitle: PropTypes.string,
  };
  defaultProps = {
    siteTitle: ``,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.toggleMenu = this.toggleMenu.bind(this)
  };

  toggleMenu() {
    this.setState({isMenuOpen: !this.state.isMenuOpen})
  };

  render() {
    return (
      <header>
        <div className={"desktop-header m-hide"}>
          <div id={"non-sticky-header"}>
            <div className={"container"}>
              <a href={"https://www.facebook.com/"} target={"_blank"} rel={"noreferrer noopener"}>
                <img className={"icon"} alt={"Facebook icon"} src={facebookLogo}/>
              </a>
              <a href={"https://www.instagram.com/"} target={"_blank"} rel={"noreferrer noopener"}>
                <img className={"icon"} alt={"Instagram icon."} src={instagramLogo}/>
              </a>
              <a href={"https://www.linkedin.com/"} target={"_blank"} rel={"noreferrer noopener"}>
                <img className={"icon"} alt={"LinkedIn icon."} src={linkedInLogo}/>
              </a>
              <a href={"tel:1-617-682-5663"}>
                <div>
                  <img className={"icon"} alt={"Phone icon"} src={phoneLogo}/>
                  <span>+1-617-682-5663</span>
                </div>
              </a>
            </div>
          </div>
          <div id={"sticky-header"}>
            <div className={"container"}>
              <a href={"/"}>
                <img alt={"Avantcy Marketing Logo"} src={avantcyLogo}/>
              </a>
              <div id={"navbar"}>
                <a href="/">Home</a>
                <a href="/about">About us</a>
                <div className="dropdown">
                  <a href="/marketing-services" className="dropbtn">Services
                    <svg id={'nav-dropdown-icon'} xmlns="http://www.w3.org/2000/svg"
                         xmlns="http://www.w3.org/1999/xlink"
                         height="10px"
                         width="10px" fill="#000000" version="1.0" x="0px" y="0px" viewBox="0 0 100 100"
                         xmlSpace="preserve">
                      <g>
                        <path
                          d="M47,73.7L6.5,33.2C4.8,31.5,4.5,28.8,6,27c1.7-2.1,4.8-2.2,6.7-0.3L47,61c1.6,1.6,4.3,1.6,5.9,0l34.3-34.3   c1.8-1.8,4.6-1.8,6.4,0c1.8,1.8,1.8,4.6,0,6.4L53,73.7C51.3,75.4,48.7,75.4,47,73.7z"/>
                      </g>
                    </svg>
                  </a>
                  <div className="dropdown-content">
                    <a href="/marketing-services/social-media-marketing">Social Media Marketing</a>
                    <a href="/marketing-services/seo">SEO | Search Engine Optimization</a>
                    <a href="/marketing-services/pay-per-click">PPC | Pay Per Click</a>
                    <a href="/marketing-services/web-design">Website Design and Development</a>
                    <a href="/marketing-services/content-marketing">Content Marketing</a>
                  </div>
                </div>
                <a href="/contact">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
        <div className={"mobile-header m-show d-none"}>
          <div id={"sticky-header"}>
            <div className={"px-4 container justify-content-between"}>
              <a href={"/"}>
                <img alt={"Avantcy Marketing Logo"} src={avantcyLogo}/>
              </a>
              <div>
                <img className={"menu-button"} alt={"Menu Button"} src={menuButton} onClick={this.props.onMenuToggle}/>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };
}

export default Header;

// Reference: https://codepen.io/michaelmcshinsky/pen/VrrJxZ
